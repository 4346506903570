<template>
    <div v-if="!_mobile" style="background-color: #1C1C1E; height: calc(300px / 1.5); float: left; width: 100%;">
        <div class="main-chapter-block-inner">
            <div style="width: 100%; padding-top:30px; padding-bottom: 30px; float: left;">
            <span style="color: #8D8D93; font-size: 14px;">
              Наша команда работает в рамках законодательства РФ: все автомобили проходят официальное оформление через таможенные органы. Также мы предоставляем полный комплект необходимой документации, чтобы уберечь вас от рисков в будущем. Если у вас остались вопросы, оставьте заявку на обратный звонок, и наш менеджер свяжется с вами в удобное время.
            </span>
            </div>
            <div style="width:100%; height: 1px; background-color: #8D8D9350;; float: left;"></div>
            
            <div style="width: 50%; float: left;">
            <div style="float: left">
                <img src="/img/logo.png" style="width: calc(368px / 1.25); margin-top: 24px;"/>
            </div>
            </div>
            <div style="width: 50%; float: right; text-align: right;margin-top: 30px;">
                <span style="color: #8D8D93; font-size: 14px;">Все права защищены. 2024</span>
            </div>

        </div>    
    </div>
</template>

<script>
import { vueDetectMobile } from '@/components/VueDetectMobile.js'

export default {
  name: 'FooterComponent',
  components: {

  },
  computed: {

  },
  methods: {

  },
  data() {
    return {      
      _mobile: false
    }
  },
  async mounted() {    
    this._mobile = vueDetectMobile()

  }
}
</script>