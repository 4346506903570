<template>
    <div style="width: 100%; float: left; background-color: #1C1C1E;">
        <div style="margin-top: 25px; margin-left: 15px; width: calc(100% - 30px);">
            <span style="color: #8D8D93; font-size: 9px;">
                Наша команда работает в рамках законодательства РФ: все автомобили проходят официальное оформление через таможенные органы. Также мы предоставляем полный комплект необходимой документации, чтобы уберечь вас от рисков в будущем. Если у вас остались вопросы, оставьте заявку на обратный звонок, и наш менеджер свяжется с вами в удобное время.
            </span>
        </div>
        <div style="margin-top: 15px; margin-left: 15px; width: calc(100% - 30px); height: 1px; background-color: #8D8D9350;"></div>

        <div style="margin-top: 15px; margin-left: 15px; width: calc(100% - 30px); height: 1px; margin-bottom: 25px;">
            <div style="float: left">
                <img src="/img/logo.png" style="width: 131px;"/>
            </div>
            <div style="float: right">
                <span style="color: #8D8D93; font-size: 10px;">Все права защищены. 2024</span>
            </div>
        </div>
    </div>
</template>