<template>
  <router-view/>
</template>

<style>
html, body, #app, main {
  height: 100%;
  background: #000000; 
  margin: 0px;
  /*    */
}

#app {
  font-family: Helvetica, Verdana, Geneva, Tahoma, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: left;
  color: #000000;

  width: 100%;
}
.main-chapter-block-inner {
  width: 1024px; margin-left: auto; margin-right: auto; height: 100%;
}
.main {
  width: 100%;
  height: 100%;
  position: relative;
}
.main-block-outer {
  float: left; width: 100%; 
}
.main-block-inner {
  width: 1024px; margin-left: auto; margin-right: auto;
}
</style>
