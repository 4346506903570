<template>
  <div class="splash" v-if="_is_splash">
    <div class="splash-inner fade-in">
      <img src="/img/small_logo.png"/>
    </div>

  </div>

  <div class="main" v-if="_mobile">
    <div class="main-m-header">
      <img src="/img/logo.png"/>
    </div>

    <div :style="getCarMobileStyle">
      <div class="main-m-banner-gr"></div>

      <div class="main-m-banner-inner">
        <div class="main-m-banner-inner-block">
          <span style="font-size: 28px; color: white; font-weight: 700;">
            Шоурум автомобилей<br/>
            премиум-класса
          </span>
        </div>

        <div class="main-m-banner-inner-block" style="margin-top: 5px;">
          <span style="font-size: 14px; color: white; ">            
            Новейшие и культовые модели<br/>
            в наличии и под заказ 
          </span>
        </div>

        <div class="main-m-banner-inner-block" style="margin-top: 25px;">
          <button class="main-m-banner-inner-order" v-on:click="openOrder()">
            <span>
              Заказать автомобиль
            </span>
          </button>
        </div>

        <div class="main-m-banner-inner-block" style="margin-top: 15px; margin-bottom: 40px;">
          <button class="main-m-banner-inner-catalog" v-on:click="gotoCatalog()">
            <span>
              Перейти в каталог
            </span>
          </button>
        </div>


      </div>
    </div>

    <div class="main-m-promo" style="margin-top: 70px;">
      <div class="main-m-promo-title">
        <span>
          Широкий выбор суперкаров для вашей коллекции
        </span>
      </div>

      <div class="main-m-promo-img-block">
        <div class="main-m-promo-img-block-bg" style="background-image: url('/img/promo_1.jpg');"></div>
      </div>

      <div class="main-m-promo-button-block">
        <button v-on:click="gotoCatalog()">
            <span>
              Перейти в каталог
            </span>
          </button>
      </div>
    </div>

    <div class="main-m-promo-sep">
      <div class="main-separator-block-separator"></div>
    </div>

    <div class="main-m-promo">
      <div class="main-m-promo-title">
        <span>
          Проведем сделку «под ключ» и возьмем на себя заботы после покупки
        </span>
      </div>

      <div class="main-m-promo-img-block">
        <div class="main-m-promo-img-block-bg" style="background-image: url('/img/promo_2.jpg');"></div>
      </div>

      <div class="main-m-promo-button-block">
        <button v-on:click="gotoCatalog()">
            <span>
              Перейти в каталог
            </span>
          </button>
      </div>
    </div>

    <div class="main-m-promo-sep">
      <div class="main-separator-block-separator"></div>
    </div>

    <div class="main-m-promo">
      <div class="main-m-promo-title">
        <span>
          Доставим винтажный автомобиль и современную модель в редкой комплектации
        </span>
      </div>

      <div class="main-m-promo-img-block">
        <div class="main-m-promo-img-block-bg" style="background-image: url('/img/promo_3.jpg');"></div>
      </div>

      <div class="main-m-promo-button-block">
        <button v-on:click="gotoCatalog()">
            <span>
              Перейти в каталог
            </span>
          </button>
      </div>
    </div>

    <div class="main-m-promo-sep">
      <div class="main-separator-block-separator"></div>
    </div>

    <div class="main-m-promo">
      <div style="width: calc(100% - 30px); float: left; margin-left: 15px;">
        <span style="color: #8D8D93; font-size: 14px;">
          Коллекция новых и ретро-автомобилей уже ждет вас в нашем шоуруме. Мы работаем с брендами Porsche, Rolls-Royce, Bentley, Lamborghini, Ferrari, Mercedes-Benz. Если нужной модели не окажется в наличии, мы организуем ее доставку из Европы, США, Азии и Ближнего Востока. Благодаря нашей партнерской сети транспортировка автомобиля в Москву занимает от пяти дней. На каждом этапе вы получаете фотоотчет.
        </span>
      </div>
      <div class="main-m-promo-img-block">
        <div class="main-m-promo-img-block-bg" style="background-image: url('/img/promo_4.jpg');"></div>
      </div>
    </div>

    <div class="main-m-promo-sep">
      
    </div>


    <FooterMobileComponent/>
  </div>

  <div class="main" v-if="!_mobile">
    <div class="main-header-outer">
      <div class="main-header-inner">

        <div class="main-header-inner-logo">
          <img src="/img/logo.png"/>
        </div>

        <div class="main-header-inner-order">
          <button v-on:click="openOrder()">
            <span>Хочу заказать автомобиль →</span>
          </button>
        </div>
      </div>
    </div>

    <div :style="getCarStyle">
      <div class="main-promo-outer">
        <div class="main-promo-inner">
          <div class="main-promo-inner-title">
            <span>Шоурум автомобилей премиум-класса</span>
          </div>
          <div class="main-promo-inner-subtitle">
            <span>Новейшие и культовые модели в наличии и под заказ</span>
          </div>
          <div v-on:click="gotoCatalog()" class="main-promo-inner-button">
            <span>Перейти в каталог</span>
          </div>
      </div>
      </div>

    </div>

    <div class="main-chapter-block " style="margin-top: 90px;">
      <div class="main-chapter-block-inner">
        <div class="main-chapter-block-text">
          <div class="main-chapter-block-text-inner">
            <div>
              <span>
                Широкий выбор суперкаров<br/>
                для вашей коллекции
              </span>
            </div>
            <div class="main-chapter-block-text-inner-button" v-on:click="gotoCatalog()">
              <span>Перейти в каталог</span>
            </div>
          </div>
        </div>
        <div class="main-chapter-block-img" style="background-image: url('/img/promo_1.jpg')"></div>
      </div>
    </div>

    <div class="main-separator-block">
      <div class="main-separator-block-inner">
        <div class="main-separator-block-separator"></div>
      </div>      
    </div>
    
    <div class="main-chapter-block ">
      <div class="main-chapter-block-inner">
        <div class="main-chapter-block-img" style="background-image: url('/img/promo_2.jpg')"></div>

        <div class="main-chapter-block-text">
          <div class="main-chapter-block-text-inner">
            <div style="text-align: right;">
              <span>
                Проведем сделку «под ключ» <br/>
                и возьмем на себя <br/>
                заботы после покупки
              </span>
            </div>
            <div class="main-chapter-block-text-inner-button" style="float: right;" v-on:click="gotoCatalog()">
              <span>Перейти в каталог</span>
            </div>
          </div>
        </div>

        
      </div>
    </div>

    <div class="main-separator-block">
      <div class="main-separator-block-inner">
        <div class="main-separator-block-separator"></div>
      </div>      
    </div>

    <div class="main-chapter-block ">
      <div class="main-chapter-block-inner">
        <div class="main-chapter-block-text">
          <div class="main-chapter-block-text-inner">
            <div>
              <span>
                Доставим винтажный автомобиль <br/>
                и современную модель в<br/>
                редкой комплектации
              </span>
            </div>
            <div class="main-chapter-block-text-inner-button" v-on:click="gotoCatalog()">
              <span>Перейти в каталог</span>
            </div>
          </div>
        </div>
        <div class="main-chapter-block-img" style="background-image: url('/img/promo_3.jpg')"></div>
      </div>
    </div>

    <div class="main-separator-block">
      <div class="main-separator-block-inner">
      </div>      
    </div>

    <div style="float: left; width: 100%;">
      <div class="main-chapter-block-inner">
        <span style="color: #8D8D93; font-size: 18px;">
          Коллекция новых и ретро-автомобилей уже ждет вас в нашем шоуруме. Мы работаем с брендами Porsche, Rolls-Royce, Bentley, Lamborghini, Ferrari, Mercedes-Benz. Если нужной модели не окажется в наличии, мы организуем ее доставку из Европы, США, Азии и Ближнего Востока. Благодаря нашей партнерской сети транспортировка автомобиля в Москву занимает от пяти дней. На каждом этапе вы получаете фотоотчет.
        </span>
      </div>
    </div>

    <div style="float: left; height: calc(719px / 1.5); width: 100%; margin-top: 20px;">
      <div class="main-chapter-block-inner">
        <div class="main-chapter-block-img" style="background-image: url('/img/promo_4.jpg'); width: 100% !important;"></div>
      </div>
    </div>

    <div class="main-separator-block">
      <div class="main-separator-block-inner">
      </div>      
    </div>

  </div>

  <FooterComponent/>
</template>

<style scoped>
.main-promo-inner-button span {
  font-size: 18px; font-weight: regular;
}
.main-promo-inner-button {
  cursor: pointer;
  width: 160px; background-color: white; color: black;margin-top: 20px; padding: 10px 20px 10px 20px; border-radius: 5px;
}
.main-promo-inner-subtitle span {
  font-size: 23px; font-weight: regular;
}
.main-promo-inner-subtitle {
  margin-top: 10px;
}
.main-promo-inner-title span {
  font-size: 45px; font-weight: bold;
}
.main-promo-inner {
  margin-left: auto; margin-right: auto; width: 1024px;  color: white
}
.main-promo-outer {
  width: 100%; position: absolute; bottom: 85px;
}

.main-header-inner-order button > span  {
  font-size: 20px; color: white;
}
.main-header-inner-order button  {
  cursor: pointer; background-color: transparent; border-style:solid; border: 1px solid #ffffff50; border-radius: 5px; padding: 10px 20px 10px 20px
}
.main-header-inner-order {
  float: right;
}
.main-header-inner-logo img {
  width: calc(540px / 1.25); margin-top: 24px
}
.main-header-inner-logo {
  float: left; height: 100%; line-height: calc(148px / 1.5);
}
.main-header-inner {
  color: white; width: 1024px; margin-left: auto; margin-right: auto; height: calc(148px / 1.5); line-height: calc(148px / 1.5);
}
.main-header-outer {
  width: 100%; height: calc(148px / 1.5); position: fixed; top: 0; left: 0; background-color: #00000055; z-index: 900; backdrop-filter: blur(12px);
}

.main-m-promo-sep {
  width: calc(100% - 30px); float: left; margin-left: 15px; height: 100px
}
.main-m-promo-button-block button span {
  color: white; font-size: 18px;
}
.main-m-promo-button-block button {
  width: 100%; border-radius: 5px; height: 53px; border: 1px solid #A6A6AD; background-color: #ffffff15;
}
.main-m-promo-button-block {
  width: calc(100% - 30px); float: left; margin-left: 15px; margin-top: 10px;
} 
.main-m-promo-title span {
  font-size: 22px; font-weight: 300; color: white;
}
.main-m-promo-title {
  width: calc(100% - 30px); float: left; margin-left: 15px;
}
.main-m-promo-img-block-bg {
  width: 100%; height: 100%; 
  overflow: hidden; border-radius: 10px; float: left; background-repeat: no-repeat; background-position: center;
  background-size: cover;
}
.main-m-promo-img-block {
  width: calc(100% - 30px); float: left; margin-left: 15px; margin-top: 25px;height: 256px;
}
.main-m-promo {
  width: 100%; float: left;
}

.main-m-banner-inner-order span {
  color: white; font-size: 18px;
}
.main-m-banner-inner-catalog span {
  color: #000000; font-size: 18px;
}
.main-m-banner-inner-catalog {
  width: 100%; border-radius: 9px; border: 1px solid #ffffff; height: 53px; background-color: white;
}
.main-m-banner-inner-order {
  width: 100%; border-radius: 9px; border: 1px solid #ffffff; height: 53px; background-color: transparent;
}
.main-m-banner-inner-block {
  float: left; margin-left: 25px; margin-right: 25px; width: calc(100% - 50px)
}
.main-m-banner-inner {
  width: 100%; position: absolute; bottom: 0; left: 0;
}
.main-m-banner-gr {
  width: 100%; height: 60%; position: absolute; left: 0; bottom: 0; background: linear-gradient(180deg, #00000000, #000000)
}

.main-m-header img {
  width: 85%; margin-top: 50px; margin-left: 7%;
}
.main-m-header {
  width: 100%; height: 119px; position: fixed; top: 0; left: 0; background-color: #00000055; z-index: 900; backdrop-filter: blur(12px);
}

.fade-in {
	opacity: 1;
	animation-name: fadeInOpacity;
	animation-iteration-count: 1;
	animation-timing-function: ease-in;
	animation-duration: 2s;
}

@keyframes fadeInOpacity {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

@keyframes fadeOutOpacity {
	0% {
		opacity: 1;
	}
	100% {
		opacity: 0;
    display: none;
	}
}

.splash-inner img {
  width: calc(306px / 1.5); height: calc(383px / 1.5)
}
.splash-inner {
  text-align:center;position: relative; top: 50%; -webkit-transform: translateY(-50%); -ms-transform: translateY(-50%); transform: translateY(-50%);
}

.splash {
  width: 100%; height: 100%; position: fixed; background-color: black; z-index: 999;
}

.main-chapter-block-img {
  overflow: hidden; border-radius: 10px; height: 100%; width: 50%; float: left; background-repeat: no-repeat; background-position: center;
  background-size: cover; 
}
.main-chapter-block-text-inner span {
  color: white; font-size: 20px;
}
.main-chapter-block-text-inner-button span {
  font-size: 14px; font-weight: regular;
}
.main-chapter-block-text-inner-button {
  border: 1px solid #A6A6AD; width: 124px; background-color: #ffffff15; color: white ; margin-top: 20px; padding: 7px 12px 7px 12px; border-radius: 5px;
  cursor: pointer;
}
.main-chapter-block-text-inner {
  position: relative; top: 50%; -webkit-transform: translateY(-50%); -ms-transform: translateY(-50%); transform: translateY(-50%);
}
.main-chapter-block-text {
  width: 50%; float: left;height: 100%;
}

.main-chapter-block {
  float: left; height: calc(500px / 1.5); width: 100%; 
}

.main-separator-block-separator {
  height:1px;width:100%; background-color: #7F2DD080;position: relative; top: 50%; -webkit-transform: translateY(-50%); -ms-transform: translateY(-50%); transform: translateY(-50%);
}
.main-separator-block-inner {
  width: 1024px; margin-left: auto; margin-right: auto; height: 100%;
}
.main-separator-block {
  float: left; height: calc(242px / 1.5); width: 100%;
}

</style>

<script>
import { vueDetectMobile } from '@/components/VueDetectMobile.js'
import FooterComponent from '@/components/FooterComponent.vue'
import FooterMobileComponent from '@/components/FooterMobileComponent.vue'

export default {
  name: 'HomeView',
  components: {
    FooterComponent,
    FooterMobileComponent
  },
  computed: {
    getCarMobileStyle() {
      var ratio = window.devicePixelRatio || 1;
      var width = screen.width //* ratio;
      var height = screen.height //* ratio;
      // var width = window.innerWidth;
      // var height = window.innerHeight;
      return {           
        width: `${width}px`,
        height: `${height}px`,
        position: `relative`,
        float: `left`,
        'background-repeat': `no-repeat`,
        'background-position': `center`,
        'background-size': `auto 100%`,
        'background-image': `url("/img/landing-mobile.jpg")` 
      }
    },
    getCarStyle() {
      var width = window.innerWidth;
      var height = window.innerHeight;
      return {           
        width: `${width}px`,
        height: `${height}px`,
        position: `relative`,
        float: `left`,
        'background-repeat': `no-repeat`,
        'background-position': `center`,
        'background-size': `auto 100%`,
        'background-image': `url("/img/landing-small.jpg")` 
      }
    }
  },
  methods: {
    openOrder() {
      this.$router.push({ path: `/order`, query: { type: 'blank' }}); 
    },
    gotoCatalog() {
      // location = "/catalog"
      this.$router.push({ path: `/catalog`}); 
    }
  },
  data() {
    return {
      _is_splash: true,
      _mobile: false
    }
  },
  async mounted() {    
    this._mobile = vueDetectMobile()

    if(process.env.VUE_APP_DEBUG == 1) {
      this._is_splash = false
    } else {
      setTimeout(() => {
        this._is_splash = false
      }, 3000)
    }


  }
}
</script>
