import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/catalog',
    name: 'CatalogView',
    component: function () {
        return import('../views/CatalogView.vue')

    }
  },
  {
    path: '/order',
    name: 'OrderView',
    component: function () {
        return import('../views/OrderView.vue')

    }
  },
  {
    path: '/car/:id',
    name: 'CarView',
    component: function () {
        return import('../views/CarView.vue')

    }
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
